<template>
  <div>
    <el-form
      v-loading="loading"
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="demo-ruleForm"
    >
      <el-form-item
        id="match_news_title"
        label-width="200px"
        label="标题"
        prop="match_news_title"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="match_news_title"
            v-model="dataForm.match_news_title"
            placeholder="请输入标题"
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 示意图 -->
      <div class="flex">
        <el-form-item label="示意图" label-width="200px"  id="img">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeTopImageUpload"
            action="#"
            drag
            :http-request="httpUploadRequest"
          >
            <img v-if="dataForm.img" :src="dataForm.img" class="avatar" />
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="示意图展示格式"
          label-width="200px"
          id="img_type"
        >
          <el-radio v-model="dataForm.img_type" label="0">重要</el-radio>
          <el-radio v-model="dataForm.img_type" label="1">一般</el-radio>
        </el-form-item>
      </div>
      <el-form-item
        id="match_news_type"
        label-width="200px"
        label="类型"
        prop="match_news_type"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-select v-model="dataForm.match_news_type" style="width: 200px">
            <el-option :value="1" label="公告"></el-option>
            <el-option :value="2" label="新闻"></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        id="match_news_status"
        label-width="200px"
        label="跳转类型"
        prop="match_news_status"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-select v-model="dataForm.match_news_status" style="width: 200px">
            <el-option :value="1" label="详情"></el-option>
            <el-option :value="2" label="链接"></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        v-if="dataForm.match_news_status == 2"
        id="match_news_links"
        label-width="200px"
        label="公众号跳转链接"
        prop="match_news_links"
        style="margin-bottom: 35px"
      >
        <el-col :span="12">
          <el-input
            ref="match_news_title"
            v-model="dataForm.match_news_links"
            placeholder="请输入公众号跳转链接"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item
        v-if="dataForm.match_news_status == 2"
        id="match_website_links"
        label-width="200px"
        label="官网文章链接"
        prop="match_website_links"
        style="margin-bottom: 35px"
      >
        <el-col :span="12">
          <el-input
            ref="match_news_title"
            v-model="dataForm.match_website_links"
            placeholder="请输入官网文章链接"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item
        v-if="dataForm.match_news_status == 1"
        :label="dataForm.match_news_type == 1 ? '公告详情' : '新闻详情'"
        label-width="200px"
        prop="match_news_detail"
        id="quillEditorQiniu"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.match_news_detail"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>

      <el-form-item style="margin-top: 50px">
        <el-button
          v-loading="loading"
          type="primary"
          @click="submitForm()"
          size="large"
          >提交</el-button
        >
        <el-button type="primary" @click="goBack()" size="large"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { uploadOssUrl } from "@/utils/uploadUrl";
import { randomStr } from "@/utils/randomStr";
import { toolbarOptions } from "@/utils/RichText/toolbarOptions.js";
import { mapActions } from "vuex";
export default {
  name: "manager",
  props: {
    view_type: {
      type: String,
    },
  },
  computed: {
    isEdit: function () {
      if (this.view_type == "edit") {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
     
      loading: false,
      type: "s1",
      match_news_title: false,
      org_rule: "",
      org_prize: "",
      dataForm: {
        _id: "",
        img: "",
        img_type:'',
        match_news_title: "",
        match_news_type: "", //类型1公告 2新闻
        match_news_detail: "",
        match_news_status: 1,
        match_news_links: "",
        match_website_links: "",
      },
      rules: {
        img_type: [
          {
            required: true,
            message: "请选择示意图展示格式",
            trigger: ["blur", "change"],
          },
        ],
        img: [
          {
            required: true,
            message: "请选择KV图",
            trigger: ["blur", "change"],
          },
        ],
        match_news_title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
        match_news_type: [
          {
            required: true,
            message: "请选择类型",
            trigger: ["blur", "change"],
          },
        ],
        // match_news_detail: [
        //   {
        //     required: true,
        //     message: "请输入详情",
        //     trigger: ["blur", "change"],
        //   },
        // ],
      },
      editorOption: {
        placeholder: "请输入详情",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function (value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function (value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function (value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      editorOptionPrize: {
        placeholder: "请填写赛事奖励",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function (value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function (value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
    };
  },
  watch: {
    $route: {
      handler: function (newVal, oldVal) {
        this.setFormData();
      },
      immediate: true,
    },
  },
  methods: {
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.img = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    async setFormData() {
      if (this.$route.query.isAdd == "edit") {
        let { data } = await this.$http.newsDetail({
          _id: this.$route.query._id,
        });
        this.match_data = data;
      }

      if (
        this.match_data &&
        this.match_data._id &&
        this.$route.query.isAdd == "edit"
      ) {
        Object.keys(this.dataForm).forEach((key) => {
          if (this.match_data[key]) {
            this.dataForm[key] = this.match_data[key];
             this.dataForm.img_type = this.match_data.img_type?.toString();
          }
        });
        this.dataForm._id = this.$route.query._id;
      } else {
        this.$nextTick(() => {
          this.$refs["dataForm"].clearValidate();
        });
        this.dataForm = {
          _id: "",
          match_news_title: "",
          match_news_type: "", //类型1公告 2新闻
          match_news_detail: "",
          match_news_status: 1,
          match_news_links: "",
          match_website_links: "",
        };
      }
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          // this.loading = true;
          let d = this.dataForm;
          // 标题不能为纯空格
          let titleLength = d.match_news_title.trim().length;

          if (titleLength == 0) {
            this.$message({
              type: "error",
              message: "标题不能为纯空格",
            });
            return;
          }
          let { data, errorCode } = await this.$http.saveNotice(d);
          if (errorCode != "0000") {
            this.$message.error("保存失败");
            return;
          }
          this.loading = false;
          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.removeTabByPath(`/operate/notice/index`);
          this.removeTabByPath(`/operate/notice/add`);
          this.$router.push(`/operate/notice/index`);
          this.$bus.$emit("onMatchChanged", data);
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (id1 == "desc") {
              id1 = "quillEditorQiniu";
            }
            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }
            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    goBack() {
      this.$router.push(`/operate/notice/index`);
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadEditorRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>
<style src="../../editor.css"></style>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 675px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 800px !important;
  z-index: 99;
}

/*/deep/ .el-form-item__label {*/
/*  width: 150px!important;*/
/*}*/

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 178px;
  height: 178px;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ #quillEditorQiniuPrize .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value="custom-color"] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value="custom-color"]::before {
  content: "自定义颜色";
  color: black;
  width: fit-content;
}
</style>
